.nft-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  z-index: z("modal");
  background: $btn-bg;
  padding: 5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;

  @include media("<=phone") {
    width: 100%;
    height: 100vh;
    z-index: 100;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  img.modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5 !important;
  }

  button.close-btn {
    position: absolute;
    top: 6rem;
    right: 6rem;
    font-size: 1vw;
    font-family: $font-b;
    transition: all 0.5s ease;

    @include media("<=phone") {
      font-size: 4rem;
      top: 10rem;
      right: 7.5vw;
    }

    &:hover {
      color: $color-theme;
    }
  }

  .nft-modal-inner {
    padding: 6rem;
    width: fit-content;
    height: fit-content;
    @extend %flex-row-space;
    border: 1px dashed $color-black;

    @include media("<=phone") {
      width: 100%;
      height: 100%;
      flex-wrap: wrap;
    }

    figure {
      flex: 0 0 40%;
      max-width: 40%;
      width: 100%;
      height: fit-content;

      @include media("<=phone") {
        flex: 0 0 100%;
        max-width: 100%;
      }

      img {
        width: 100%;
        min-height: 50vh;
        height: fit-content;
      }
    }

    .nft-info-wrapper {
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;
      height: fit-content;
      @include media("<=phone") {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .title-wrapper {
        @extend %flex-row-space;
        width: 100%;

        h1 {
          font-size: 3vw;

          @include media("<=phone") {
            font-size: 5rem;
          }
        }
      }
      .line {
        margin: 2rem 0;
      }

      button.buy-btn {
        font-size: 1.2vw;
        padding: 1rem 2rem;
        border-radius: 50px;
        background: $color-theme;
        font-family: $font-b;
        color: $color-white;
        margin-top: 2rem;
        transition: all 0.5s ease;

        @include media("<=phone") {
          font-size: 3rem;
          padding: 2rem 4rem;
        }

        &:hover {
          background: $color-black;
        }
      }
    }
  }
}

// --------------- When user click from profile page -----
.nft-editing-wrapper {
  margin-top: 2vh;
}

.nft-editing-input-wrapper {
  display: flex;
  align-items: center;
}

.input {
  background: transparent;
  border: none;
  border: 2px dashed $color-theme;
  padding: 1rem;
  font-size: 1.5rem;
}

.number__input {
  margin-right: 1rem;
}

.input__label {
  font-size: 1.2vw;
}

.nft-editing-btns-wrapper {
  margin-top: 2vh;
}

.nft-editing-btn {
  padding: 1rem 2rem;
  background-color: $color-black;
  color: $color-white;
  border-radius: 5rem;
  font-family: $font-c;
  font-size: 1.5rem;
}

.nft-edit-btn {
  margin-right: 1vw;
}

.nft-remove-btn {
  background-color: $color-danger;
}

// .nft-listing-btn {
// }
