.big-title {
  font-size: 15vw;
  font-weight: bold;
  color: $color-white;
  font-family: $font-b !important;
  line-height: 1;
  text-align: center;
}

.medium-title {
  font-size: 2.2vw;
  font-weight: 500;

  @include media("<=phone") {
    font-size: 3rem;
  }
}

.title {
  font-size: 4vw;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;

  @include media("<=phone") {
    font-size: 5rem;
  }
}

.mini-title {
  font-size: 2vw;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;

  @include media("<=phone") {
    font-size: 4rem;
  }
}

.paragraph {
  font-size: 1vw;
  font-weight: 500;
  line-height: 1.4;

  @include media("<=phone") {
    font-size: 2rem;
  }

  span.big {
    float: left;
    font-size: 500%;
    width: 0.8em;
    line-height: 80%;
  }
}

.banner-title {
  font-size: 2.8vw;
  font-weight: 500;
  font-family: $font-b !important;
  background: $color-theme;
  color: $color-white;
  text-align: center;
  padding: 2rem 0;

  @include media("<=phone") {
    font-size: 6rem;
  }
}

.btn {
  background: rgba($color: $btn-bg, $alpha: 0.25%);
  border: 1px solid $color-theme;
  border-radius: 50%;
  box-shadow: 4px 4px 0px $color-theme;
  font-size: 1.5vw;
  font-weight: 600;
  padding: 2rem 5rem;
  font-family: $font-c !important;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 0px $color-theme;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 90%;
    border: 1px dashed $color-theme;
    border-radius: 50%;
  }

  @include media("<=phone") {
    font-size: 2rem;
  }
}
