.suspense__loader__wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $color-black;
  display: grid;
  place-items: center;
}

.suspense__loader__title {
  font-size: 6vw;
  color: $color-white;

  span {
    animation: fade 2s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      animation-delay: 1s;
    }
    &:nth-child(3) {
      animation-delay: 1.5s;
    }
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
