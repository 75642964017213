.account__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z("modal");
  background: rgba($color: $color-theme, $alpha: 0.2);
  @extend %center;
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__inner {
    width: 30vw;
    height: 70vh;
    overflow: hidden;
    position: relative;
    background: $btn-bg;
    padding: 5rem;

    @include media("<=phone") {
      width: 90vw;
      height: fit-content;
    }

    img.img-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.5 !important;
    }

    .account__modal__content {
      border: 1px dashed $color-black;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      padding: 5rem;

      h1.modal-title {
        font-size: 3vw;
        text-align: center;
      }

      .line {
        margin: 2rem 0;
      }

      .account-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;

        figure {
          max-width: 50%;
          width: 100%;
          height: fit-content;
          overflow: hidden;

          svg {
            display: block;
            margin: 0 auto;
            width: 100%;
          }
        }

        .account-info-content {
          width: fit-content;
          height: fit-content;
          text-align: center;
          margin-top: 2rem;

          button.copy-btn {
            color: $color-white;
            background: $color-theme;
            padding: 1rem 1.5rem;
            margin-top: 2rem;
          }
        }
      }

      button.modal-btn {
        padding: 1rem 1.5rem;
        color: $color-white;
        background: $color-theme;
      }

      button.dis-btn {
        margin-top: 2rem;
        color: $color-theme;
        font-size: 1.1vw;

        @include media("<=phone") {
          font-size: 3rem;
          margin-top: 4rem;
        }
      }
    }
  }
}
