.page {
  //   @extend %page;
  @extend %scrolljeck;
  overflow-y: scroll !important;
}

.page__wrapper {
  position: relative;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   min-height: 100vh;
  //   height: fit-content;
}

.paper-color-bg {
  width: 100%;
  height: 100%;
}
