// -------------------- Title --------------------
.profile__header {
  width: 100%;
  height: fit-content;
  padding: 15vh 0 10vh 0;
}

.profile__inner {
  width: 100%;
  @extend %inner-section;
}

// -------------------- Content --------------------
.profile__content__wrapper {
  content: "";
  min-height: 300px;
}

.profile__content__container {
  @extend %inner-section;
}

// -------------------- Tabs --------------------
.profile__tabs__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  // --- bottom border
  &::after {
    content: "";
    position: absolute;
    bottom: -0.8vh;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #2e2929;
  }
}

// -------------------- Tab button --------------------
.profile__tabs__btn__wrapper {
  position: relative;
  margin-right: 2vw;

  &:last-child {
    margin-right: 0;
  }
}

.profile__tabs__btn {
  position: relative;
  font-family: $font-b;
  text-transform: uppercase;
  font-size: 1.8vw;
  color: $color-black;

  @include media("<=phone") {
    font-size: 2.7rem;
  }

  & .profile__tabs__btn__underline {
    position: absolute;
    bottom: -1.2vh;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #2e2929;
  }
}

// -------------------- Tabs Content --------------------
.profile__tabs__content__wrapper {
  content: "";
}

// -------------------- NFT Ranking tab --------------------
.nft__ranking__content__wrapper {
  padding: 6vh 0;
}

.nft__ranking__content__container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 3vh;
  column-gap: 2vw;
}

// -------------------- Claim Rewards --------------------
.claimrewards__content__wrapper {
  padding: 10vh 0;
}

.claimrewards__content__lists {
  width: 100%;
}

.claimrewards__content__list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 1.5vh;

  @include media("<=phone") {
    width: 80%;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &.w-100 {
    @include media("<=phone") {
      width: calc(100% - 20px) !important;
    }
  }

  & h3.claimrewards__content__list__item__title {
    font-size: MIN(1.8vw, 3rem);
    margin-right: 1.2vw;
    font-family: $font-c !important;
    font-weight: 600;

    @include media("<=phone") {
      font-size: 3rem;
    }
  }

  & p.claimrewards__content__list__item__description {
    font-size: 1.8vw;

    @include media("<=phone") {
      font-size: 3rem;
    }
  }
}

.claim__btn__wrapper {
  width: fit-content;
  margin: 5vh auto 0 auto;
  display: flex;
  grid-area: auto;
  gap: 20px;
  @include media("<=phone") {
    margin: 3vh auto 0 auto;
    font-size: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.holder__btn {
  background-color: $color-black;
  color: $color-white;
  padding: 1.5rem 2rem;
  border-radius: 5rem;
  font-size: 1.2vw;
  font-family: inherit;
  // font-family: $font-b;
  text-transform: uppercase;
  &:disabled {
    cursor: not-allowed;
    background-color: #2e2929;
  }
  p {
    font-family: $font-b;
  }
  @include media("<=phone") {
    font-size: 3rem;
    padding: 3rem 2rem;
  }
}

.claim__btn {
  background-color: $color-black;
  color: $color-white;
  padding: 1.5rem 4rem;
  border-radius: 5rem;
  font-size: 1.2vw;
  font-family: $font-b;
  text-transform: uppercase;

  @include media("<=phone") {
    font-size: 4rem;
    padding: 3rem 10rem;
  }
}

.info__btn {
  font-size: 2rem;
  width: 6rem;
  height: 6rem;
  background: $color-black;
  color: $color-white;
  border-radius: 50%;
  position: relative;
}

.info-box {
  width: auto;
  height: auto;
  background: $color-theme;
  padding: 2rem;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &-inner {
    width: 100%;
    height: 100%;
    padding: 2rem;
    border: 1px dashed $color-theme;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.9;
  }
}

.filter-box {
  width: 10vw;
  height: auto;
  background: transparent;
  padding: 0 2rem;
  transition: all 0.5s ease;
  border: 2px solid $color-black;
  box-shadow: 3px 3px 0px $color-theme;
  margin-top: 20px;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    box-shadow: none;
  }

  @include media("<=phone") {
    width: 100%;
    text-align: center;
    margin-top: 4rem;
  }

  select.filter-select {
    text-align: center;
    background: transparent;
    color: $color-theme;
    width: 100%;
    height: auto;
    padding: 2rem 0;
    border: none;
    outline: none;
    font-size: 1.2vw;
    font-family: $font-b;

    @include media("<=phone") {
      width: 100%;
      font-size: 4rem;
      font-weight: bold;
    }

    &:hover,
    &:focus,
    &:active,
    &:checked {
      outline: none;
      border: none;
    }

    option {
      padding: 1rem;
    }
  }
}
