.header {
  position: relative;
  width: 100vw;
  height: 92rem;
  overflow: hidden;
}

.header__content__wrapper {
  position: relative;
  height: 100%;
}

.header__media__wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header__media {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.header__mashed__paper__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.header__mashed__media {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  object-fit: cover;
  transform: scale(1.1);
}

.section__articles__list {
  //   padding: 10vh 0;
}

.section__articles__title {
  margin-bottom: 5rem;
}

.section__content__wrapper {
  @extend %inner-section;
}

.articles__list__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 4rem;
  grid-column-gap: 2rem;
  margin: 0 auto;

  @include media("<=tablet") {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media("<=phone") {
    grid-template-columns: repeat(1, 1fr);
  }
}

.article__card {
  background-color: $color-paper-gray;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: all 0.5s ease;
  border: 1px solid $color-theme;
  box-shadow: 4px 4px 0px $color-theme;

  &:hover {
    box-shadow: 0px 0px 0px $color-theme;
  }
}

.article__card__media__wrapper {
  position: relative;
  height: 20vh;
  overflow: hidden;
}

.article__card__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article__card__title {
  text-transform: none;
  margin: 2rem 0;

  @include media("<=tablet") {
    font-size: 3vw;
  }

  @include media("<=phone") {
    font-size: 4.2vw;
  }
}

.article__card__paragraph {
  margin-bottom: 2rem;
  word-break: break-all;

  @include media("<=tablet") {
    font-size: 1.8vw;
  }

  @include media("<=phone") {
    font-size: 2.8vw;
  }
}

.article__card__link {
  font-weight: 600;

  @include media("<=tablet") {
    font-size: 1.8vw;
  }

  @include media("<=phone") {
    font-size: 2.8vw;
  }
}

.article__card__description__wrapper {
  margin-top: 2rem;
}

.article__card__date__wrapper {
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-gray;

  @include media("<=tablet") {
    flex-wrap: wrap;
    font-size: 1.5vw;
  }

  @include media("<=phone") {
    font-size: 2.5vw;
  }
}

.article__card__date {
  font-weight: 600;
  color: $color-gray;
  margin-right: 2rem;

  @include media("<=tablet") {
    margin-bottom: 1rem;
  }
}

.pagination__wrapper {
  padding: 5vh 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & .pagination__button {
    padding: 1.5rem 2rem;
    font-size: 0.8vw;
    margin-right: 5rem;
    position: relative;
    border: 1px solid $color-theme;
    box-shadow: 4px 4px 0px $color-theme;

    @include media("<=tablet") {
      font-size: 1.8vw;
    }

    @include media("<=phone") {
      font-size: 2.8vw;
    }

    &:active {
      box-shadow: 0px 0px 0px $color-theme;
    }

    &:last-child {
      margin-right: 0;
      margin-left: 5rem;
    }
  }

  .pagination__page__number {
    font-size: 1.2vw;

    @include media("<=tablet") {
      font-size: 2.2vw;
    }

    @include media("<=phone") {
      font-size: 3.2vw;
    }
  }
}

.articles {
  background: $color-paper-gray;
  .paper-color-bg {
    display: none;
  }
}
