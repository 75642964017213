.mint-header {
  @extend %section;
  padding: 15vh 0 5vh 0;
  position: relative;

  img.mint-bg {
    width: 10vw;
    height: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &-inner {
    @extend %inner-section;

    .infinite-box {
      background: $color-theme;
      padding: 3rem 2rem;
      overflow: hidden;
      color: $color-white;

      h1 {
        width: 28vw;
        text-shadow: 28vw 0 $color-white, calc(28vw * 2) 0 $color-white,
          calc(28vw * 3) 0 $color-white, calc(28vw * 4) 0 $color-white,
          calc(28vw * 5) 0 $color-white, calc(28vw * 6) 0 $color-white;
        animation: move 30s linear infinite;

        @include media("<=phone") {
          width: 52vw;
          text-shadow: 52vw 0 $color-white, calc(52vw * 2) 0 $color-white,
            calc(52vw * 3) 0 $color-white, calc(52vw * 4) 0 $color-white,
            calc(52vw * 5) 0 $color-white, calc(52vw * 6) 0 $color-white;
        }
      }
    }

    .mint-content {
      width: 100%;
      height: fit-content;
      position: relative;
      overflow: hidden;
      padding: 10vh 0 5vh 0;

      .mint-box {
        width: 100%;
        height: fit-content;
        @extend %flex-column-center;
        text-align: center;
        gap: 3rem;

        figure.mint-pic-wrapper {
          width: 50%;
          height: fit-content;

          @include media("<=phone") {
            width: 100%;
          }

          img {
            width: 100%;
            height: fit-content;
          }
        }

        h1.mini-title {
          font-family: $font-b !important;
        }

        .mint-numbers {
          @extend %flex-row-center;
          gap: 3rem;

          button {
            width: fit-content;
            height: fit-content;
            transition: all 0.5s ease;
            transform-origin: center;

            img {
              width: 100%;
              height: fit-content;
            }

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        button.mint-now-btn {
          font-size: 1.8vw;
          font-weight: 500;
          line-height: 1;
          font-family: $font-b !important;
          padding: 2rem 5rem;
          border-radius: 50px;
          background: $color-theme;
          color: $color-white;

          @include media("<=phone") {
            font-size: 5rem;
            margin-top: 4rem;
            padding: 3rem 5.5rem;
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.mint-footer {
  width: 100%;
  height: fit-content;
  padding: 5vh 0;

  &-inner {
    @extend %inner-section;
    position: relative;

    .line {
      margin: 0 0 5vh 0;
    }

    .mint-footer-content {
      width: 100%;
      height: fit-content;
      @extend %flex-row-space;
    }
  }
}

.no-items {
  font-size: 1.2vw;
}
