.nft-card {
  width: fit-content;
  height: fit-content;
  max-width: 20vw;
  padding: 2.5rem;
  background: $super-silver;
  border-radius: 10px;
  transition: all 0.5s ease;
  border: 1px solid $color-theme;
  box-shadow: 4px 4px 0px $color-theme;

  @include media("<=phone") {
    flex: 0 0 47.5%;
    max-width: 47.5%;
    width: 100%;

    @include media("<=small") {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &:hover {
    box-shadow: 0px 0px 0px $color-theme;
    figure {
      img {
        transform: scale(1.2);
      }
    }
  }

  &.no-active {
    opacity: 0;
  }

  figure {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 17vw;
      height: auto;
      transform-origin: center;
      transition: all 0.5s ease;

      @include media("<=phone") {
        width: 100%;
      }
    }
  }

  .nft-content {
    @extend %flex-row-space;
    width: 100%;
    margin: 2rem 0;

    @include media("<=phone") {
      margin: 5rem 0;
    }

    h1.nft-title {
      font-size: 2vw;

      @include media("<=phone") {
        font-size: 3rem;
      }
    }

    p {
      img {
        width: 1.3vw;
      }
    }
  }

  button {
    text-align: center;
    width: 100%;
    height: fit-content;
    padding: 2rem;
    // border-radius: 50px;
    // background: $color-theme;
    // color: $color-white;
    transition: all 0.5s ease;
    font-family: $font-a;
    font-size: 1.2vw;
    border: 1px solid $color-theme;

    @include media("<=phone") {
      font-size: 3rem;
    }

    &:hover {
      box-shadow: 4px 4px 0px $color-theme;
      //   background: $color-black;
    }
  }
}
