.wallet-box-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z("modal");
  background: rgba($color: $color-theme, $alpha: 0.2);
  @extend %center;
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.wallet-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  z-index: z("wallet-box");
  @extend %center;
  background: $btn-bg;
  padding: 5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;

  @include media("<=phone") {
    padding: 5rem 3rem;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  img.wallet-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5 !important;
  }

  button.close-btn {
    position: absolute;
    top: 6rem;
    right: 6rem;
    font-size: 1vw;
    z-index: 10;
    font-family: $font-b;
    display: grid;
    place-items: center;

    @include media("<=phone") {
      font-size: 2rem;
      top: 6rem;
      right: 4rem;
    }

    &:hover {
      color: $color-theme;
    }

    & .x-icon {
      width: 4.2rem;
      height: 4.2rem;
      transition: all 0.5s ease;

      &:hover {
        transform: rotate(360deg);
        transform-origin: center;
      }
    }
  }

  &-inner {
    width: fit-content;
    height: fit-content;
    position: relative;
    border: 1px dashed $color-black;
    padding: 8rem;

    @include media("<=phone") {
      padding: 7rem 4rem;
      width: 80vw;
    }

    h1.wallet-box-title {
      font-size: 3vw;
      text-align: center;

      @include media("<=phone") {
        font-size: 5rem;
      }
    }

    .line {
      margin: 2rem 0;
    }

    a {
      @extend %flex-row-space;

      &:hover {
        color: $color-theme;

        figure {
          transform: rotate(360deg) scale(1.1);
        }
      }

      figure {
        width: 5vw;
        height: 5vw;
        background: $color-white;
        border-radius: 50%;
        overflow: hidden;
        @extend %center;
        transition: all 0.5s ease;
        transform-origin: center;

        @include media("<=phone") {
          width: 10rem;
          height: 10rem;
        }

        img {
          width: 60%;
          height: 60%;
        }
      }

      .wallet-link-content {
        flex: 0 0 70%;
        max-width: 70%;
        width: fit-content;
        height: fit-content;

        h1.wallet-link-title {
          font-size: 1.5vw;

          @include media("<=phone") {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
