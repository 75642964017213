.error__wrapper {
  width: 100%;
  height: 100vh;
  padding: 10vh 0;
}

.error__content__wrapper {
  @extend %inner-section;
  position: relative;
  width: 100%;
  height: 100%;
}

.error__media__wrapper {
  width: 100rem;
  height: 60rem;
  position: relative;
  margin: 0 auto;
  margin-bottom: 5vh;
}

.error__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.error__message {
  text-align: center;
  border: 2px solid $color-theme;
  box-shadow: 4px 4px 0px $color-theme;
  position: relative;
  padding: 4vh;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99%;
    height: 90%;
    border: 1px dashed $color-theme;
  }
}

.error__description {
  text-align: center;
  margin-top: 4vh;
}
