.market__banner {
  padding: 15vh 0 0 0;

  &-inner {
    @extend %inner-section;

    figure {
      margin-top: 4rem;
      width: 100%;
      height: 60rem;
      border: 1px solid $color-theme;
      box-shadow: 4px 4px 0px $color-theme;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    // market-info
    .market-info {
      width: 100%;
      height: fit-content;
      margin: 10vh 0;
      padding: 5vh 0;
      border-top: 1px solid $color-theme;
      border-bottom: 1px solid $color-theme;
      @extend %flex-row-space;

      @include media("<=phone") {
        flex-wrap: wrap;
      }

      &-content {
        width: 60%;
        height: fit-content;
        @extend %flex-row-space;
        align-items: flex-start;

        @include media("<=phone") {
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
        }

        .info-box {
          flex: 0 0 20%;
          max-width: 20%;
          width: 100%;
          height: fit-content;

          h1.info-title {
            font-size: 2vw;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            @include media("<=phone") {
              font-size: 2rem;
            }

            img {
              width: 1.25vw;
              margin-right: 2rem;

              @include media("<=phone") {
                width: 1.5rem;
                margin-right: 1rem;
              }
            }
          }

          p {
            margin-top: 1rem;
          }
        }
      }

      .filter-box {
        width: 10vw;
        height: auto;
        background: transparent;
        padding: 0 2rem;
        transition: all 0.5s ease;
        border: 2px solid $color-black;
        box-shadow: 3px 3px 0px $color-theme;
        cursor: pointer;

        &:hover {
          box-shadow: none;
        }

        @include media("<=phone") {
          width: 100%;
          text-align: center;
          margin-top: 4rem;
        }

        select.filter-select {
          text-align: center;
          background: transparent;
          color: $color-theme;
          width: 100%;
          height: auto;
          padding: 2rem 0;
          border: none;
          outline: none;
          font-size: 1.2vw;
          font-family: $font-b;

          @include media("<=phone") {
            width: 100%;
            font-size: 4rem;
            font-weight: bold;
          }

          &:hover,
          &:focus,
          &:active,
          &:checked {
            outline: none;
            border: none;
          }

          option {
            padding: 1rem;
          }
        }
      }
    }
  }
}

.market-place {
  width: 100%;
  height: fit-content;
  position: relative;
  padding: 2.5vh 0 5vh 0;

  .market-place-inner {
    @extend %inner-section;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 3vh;
    column-gap: 2vw;

    @include media("<=phone") {
      justify-content: space-between;
    }
  }
}
