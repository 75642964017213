.news-article {
  @extend %page;
  overflow: hidden;
  background: $color-paper-gray;

  &.active {
    @extend %page--active;
  }
}

.news-article__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
}

.article__header__wrapper {
  position: relative;
  width: 100%;
  height: 92rem;
  overflow: hidden;
}

.article__header__content__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.article__media__bg__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.article__media__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article__mashed__wrapper {
  width: 100%;
  height: 100%;
}

.scene-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform: scale(1.1);

    @include media("<=phone") {
      transform: scale(2);
    }
  }
}

.media__paper {
  position: absolute;
  top: 32vh;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5 !important;
  z-index: 0;
}

.article__mashed__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 102%;
  height: fit-content;
  object-fit: cover;
  z-index: 5;
}

.article__content {
  position: relative;
  padding-top: 5vh;
}

.article__content__header__wrapper {
  @extend %inner-section;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5vw;
  padding: 5vh;
  margin-top: -5vh;
  margin-bottom: 6vh;
  border: 2px solid $color-theme;
  box-shadow: 4px 4px 0px $color-theme;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99%;
    height: 90%;
    border: 1px dashed $color-theme;
  }

  & span {
    font-family: $font-b !important;
  }

  @include media("<=tablet") {
    font-size: 2.5vw;
  }
}

.article__content__wrapper {
  @extend %inner-section;
}

.article__title {
  font-size: 5vw;
  line-height: 1.25;
  word-break: break-all;

  @include media("<=tablet") {
    font-size: 6vw;
  }

  @include media("<=phone") {
    font-size: 7vw;
  }
}

.article__content__text {
  margin-top: 5vh;
  white-space: pre-wrap;

  & p {
    font-size: 1.3vw;
    line-height: 1.45;
    margin-bottom: 2rem;

    @include media("<=tablet") {
      font-size: 2.2vw;
    }

    @include media("<=phone") {
      font-size: 2.8vw;
    }
  }

  & em {
    font-style: italic;
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 5rem 0 2rem 0;
    background-color: $color-theme;
    padding: 3vh;
    color: $color-white;

    & a {
      font-size: inherit;
      color: inherit;
    }
  }

  & h1 {
    font-size: 2.5vw;

    @include media("<=tablet") {
      font-size: 3.5vw;
    }

    @include media("<=phone") {
      font-size: 4.5vw;
    }
  }

  & h2 {
    font-size: 2vw;

    @include media("<=tablet") {
      font-size: 3vw;
    }

    @include media("<=phone") {
      font-size: 4vw;
    }
  }

  & h3 {
    font-size: 1.5vw;

    @include media("<=tablet") {
      font-size: 2.5vw;
    }

    @include media("<=phone") {
      font-size: 3.5vw;
    }
  }

  & h4 {
    font-size: 1.2vw;

    @include media("<=tablet") {
      font-size: 2.2vw;
    }

    @include media("<=phone") {
      font-size: 3.2vw;
    }
  }

  & h5 {
    font-size: 1vw;

    @include media("<=tablet") {
      font-size: 2vw;
    }

    @include media("<=phone") {
      font-size: 3vw;
    }
  }

  & h6 {
    font-size: 0.8vw;

    @include media("<=tablet") {
      font-size: 1.8vw;
    }

    @include media("<=phone") {
      font-size: 2.8vw;
    }
  }

  & strong {
    font-weight: 700;
  }

  & ol {
    list-style: decimal;
    margin-left: 2rem;
    margin-bottom: 2.5rem;

    & li {
      font-size: 1.3vw;
      margin-bottom: 2.8rem;

      @include media("<=tablet") {
        font-size: 2.3vw;
      }

      @include media("<=phone") {
        font-size: 3.3vw;
      }
    }
  }

  & ul {
    margin-left: 2rem;
    margin-bottom: 2rem;
    list-style: disc;

    & li {
      font-size: 1.3vw;
      margin-bottom: 1rem;

      @include media("<=tablet") {
        font-size: 2.3vw;
      }

      @include media("<=phone") {
        font-size: 3.3vw;
      }
    }
  }

  & a {
    color: $color-theme;
    text-decoration: underline;
  }

  & blockquote {
    font-size: 1.3vw;
    margin-top: 5rem;
    margin-bottom: 5rem;
    font-style: italic;
    border-left: 2px solid $color-theme;
    padding-left: 6rem;
    color: $color-gray;
    position: relative;

    @include media("<=tablet") {
      font-size: 2.3vw;
    }

    @include media("<=phone") {
      font-size: 3.3vw;
    }

    &::before {
      content: "“";
      font-size: 5rem;
      position: absolute;
      top: 0;
      left: 2rem;
      color: $color-theme;
    }

    & p {
      line-height: 1.5;
    }
  }

  & cite {
    display: block;
    margin-top: 2rem;
    font-size: 1.3vw;
    font-style: normal;
    text-align: right;

    @include media("<=tablet") {
      font-size: 2.3vw;
    }

    @include media("<=phone") {
      font-size: 3.3vw;
    }
  }

  & cite a {
    color: $color-gray;
  }

  & cite a:hover {
    color: $color-theme;
  }

  & cite::before {
    content: "—";
    margin-right: 1rem;
  }

  & cite::after {
    content: "";
  }

  & pre {
    margin-top: 2rem;
    margin-bottom: 5rem;
    font-size: 1.3vw;
    font-family: "Roboto Mono", monospace;
    background-color: $color-gray;
    color: $color-white;
    padding: 2rem;
    border-radius: 0.5rem;
    overflow-x: auto;

    @include media("<=tablet") {
      font-size: 2.3vw;
    }

    @include media("<=phone") {
      font-size: 3.3vw;
    }
  }

  & code {
    font-size: 1.3vw;
    font-family: "Roboto Mono", monospace;
    background-color: $color-gray;
    color: $color-white;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;

    @include media("<=tablet") {
      font-size: 2.3vw;
    }

    @include media("<=phone") {
      font-size: 3.3vw;
    }
  }

  & img {
    width: 100%;
    height: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
    border: 1px solid $color-theme;
    box-shadow: 4px 4px 0px $color-theme;
    object-fit: cover;
  }
}

.article__description__wrapper {
  margin-top: 2rem;

  & > div {
    margin-bottom: 1rem;
  }

  & .date {
    font-weight: 600;
    margin-right: 1rem;
  }
}

.article__content__media__container {
  margin: 5vh 0;
  width: 100%;
  height: 50vh;
  min-height: 60rem;
  position: relative;
  border: 1px solid $color-theme;
  box-shadow: 4px 4px 0px $color-theme;
}

.article__content__media {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.dashed-line {
  width: 100%;
  height: 1px;
  border: 1px dashed $color-theme;
  margin-top: 5vh;
}
