.home__header {
  width: 100%;
  height: auto;
  padding: 15vh 0 10vh 0;

  @include media("<=phone") {
    padding: 20rem 0;
  }

  &--inner {
    @extend %inner-section;
    width: 100%;
    height: auto;

    .banner-headline {
      width: 100%;
      height: auto;
      border: 2px solid $color-theme;
      padding: 3rem;
      overflow: hidden;
      box-shadow: 5px 5px 0 $color-theme;
      margin-bottom: 6rem;

      &-inner {
        width: 100%;
        height: 4rem;
        position: relative;

        h1.text {
          position: absolute;
          top: 0;
          left: 0;
          width: max-content;
          font-size: 4rem;
          line-height: 4rem;
          text-shadow:
            var(--width) 0 $color-black,
            calc(var(--width) * 2) 0 $color-black,
            calc(var(--width) * 3) 0 $color-black,
            calc(var(--width) * 4) 0 $color-black,
            calc(var(--width) * 5) 0 $color-black,
            calc(var(--width) * 6) 0 $color-black;
          animation: move 30s linear infinite;
        }
      }
    }

    .home-banner {
      width: 100%;
      height: auto;

      .marketplace-box {
        flex: 0 0 25%;
        max-width: 25%;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 2px solid $color-black;
        text-align: center;
        box-shadow: 5px 5px 0 $color-theme;
        padding: 1vw;

        @include media("<=phone") {
          display: none;
        }

        &-inner {
          width: 100%;
          height: 100%;
          @extend %flex-column-center;
          padding: 1vw;
          border: 2px dashed $color-black;

          .title-box {
            padding: 2rem 0;
            width: 100%;
            height: auto;
            background: $color-theme;

            h1 {
              color: $color-white;
            }
          }

          p {
            margin: 2rem 0;
          }
        }
      }

      &-news-wrapper {
        width: 100%;
        height: auto;
        @extend %flex-row-space;
        align-items: flex-start;
        position: relative;
        margin-top: 5rem;

        figure.home-banner-news-figure {
          flex: 0 0 70%;
          max-width: 70%;
          width: 100%;
          height: auto;
          box-shadow: 5px 5px 0 $color-black;

          @include media("<=phone") {
            flex: 0 0 100%;
            max-width: 100%;
            height: auto;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      &-news-content {
        width: 100%;
        height: auto;
        padding-top: 4rem;

        h1 {
          width: 80%;

          @include media("<=phone") {
            width: 100%;
          }
        }

        p {
          margin-top: 4rem;
          width: 80%;
          column-count: 2;

          @include media("<=phone") {
            width: 100%;
          }
        }

        .marketplace-box {
          display: none;
          position: relative;
          right: unset !important;
          top: unset !important;
          width: 100%;
          height: auto;
          max-width: 100% !important;
          flex: unset !important;

          @include media("<=phone") {
            display: block !important;
            margin-top: 4rem;

            &-inner {
              padding: 1.5rem 3rem;
            }
          }
        }
      }
    }

    .header-row {
      width: 100%;
      height: auto;
      @extend %flex-row-space;
      align-items: flex-start;

      @include media("<=phone") {
        flex-wrap: wrap;
      }

      .row-item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        width: 100%;
        height: auto;
        position: relative;

        @include media("<=phone") {
          flex: 0 0 100%;
          max-width: 100%;
          height: auto;
          border-left: none !important;
          border-right: none !important;
          border-bottom: 1px solid $color-black;
          padding: 4rem 0;
        }

        &:nth-of-type(1) {
          border-right: 1px solid rgba($color: $color-black, $alpha: 1);
        }

        &:nth-of-type(2) {
          text-align: center;

          h1.medium-title {
            margin: 2rem 0 7rem 0;
            font-family: $font-b !important;
          }
        }

        &:nth-of-type(3) {
          padding-left: 5.5%;
          border-left: 1px solid rgba($color: $color-black, $alpha: 1);

          @include media("<=phone") {
            padding-left: 0 !important;
          }
        }

        figure {
          width: auto;
          height: auto;
          margin-bottom: 2rem;
          border: 2px solid #090b37;
          box-shadow: 4px 4px 0px #090b37;

          @include media("<=phone") {
            width: 100%;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        p {
          margin-top: 2rem;
          width: 70%;

          @include media("<=phone") {
            width: 100%;
          }
        }
      }
    }

    h1.banner-title {
      margin-top: 8rem;
    }
  }
}

.title-side {
  background: $color-theme;
  color: rgba($color: $color-white, $alpha: 1);
  padding: 10vh 5vw;
  width: 100%;
  height: auto;
  text-align: center;

  h1 {
    font-family: $font-b !important;
    text-transform: unset !important;
  }

  h4 {
    margin-top: 4rem;
    font-family: $font-c !important;
    font-style: italic;
    text-transform: unset !important;
  }
}

.section {
  width: 100%;
  position: relative;
  padding: 5vh 0;

  &-inner {
    @extend %inner-section;
    overflow: hidden;
  }

  & .section__news__wrapper {
    margin-bottom: 5vh;
  }

  & .section__news__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 4rem;
    margin-top: 5vh;

    @include media("<=phone") {
      flex-wrap: wrap;

      .section__news__content__wrapper {
        width: 100%;
        padding-top: 6rem;
      }
    }

    p {
      column-count: 2;
    }
  }

  & .section__news__media__wrapper {
    width: 100%;
    height: auto;
    border: 1px solid $color-theme;
    box-shadow: 4px 4px 0px $color-theme;

    @include media("<=phone") {
      margin: 0 !important;
      width: 100%;

      img {
        display: none;

        &.section__news__media--mobile {
          display: block !important;
          width: 100%;
        }
      }
    }

    img {
      width: 100%;
      &.section__news__media--mobile {
        display: none;
      }
    }
  }
}

.news-section {
  @extend %section;
  min-height: auto !important;
  padding: 0;
  position: relative;

  &-inner {
    @extend %inner-section;
    @extend %flex-row-space;
    align-items: flex-start;
    position: relative;

    @include media("<=phone") {
      flex-wrap: wrap;

      &:after {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      background: rgba($color: $color-black, $alpha: 0.5);
      z-index: 2;
      transform: translateX(-50%);
    }

    .news-column {
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;
      height: auto;

      @include media("<=phone") {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 4rem 0;
        padding-left: 0 !important;
        padding-right: 0 !important;

        p {
          width: 100% !important;
        }
      }

      figure {
        width: 100%;
        height: auto;
        margin-bottom: 4rem;
        border: 1px solid $color-theme;
        box-shadow: 4px 4px 0px $color-theme;

        img {
          width: 100%;
          height: auto;

          &.news-pic--mobile {
            display: none;
          }

          @include media("<=phone") {
            display: none;
            &.news-pic--mobile {
              display: block !important;
            }
          }
        }
      }

      p {
        width: 90%;
      }

      &:nth-of-type(1) {
        padding-right: 5%;
        p {
          &:nth-of-type(1) {
            margin-top: 5rem;
          }

          &.last {
            margin: 1rem 0 5rem 0 !important;

            &::first-letter {
              font-size: 400%;
            }
          }
        }
        h1.mini-title {
          margin-bottom: 5rem;

          &:nth-of-type(2) {
            margin-bottom: 0;
            margin-top: 5rem;
            text-align: center;
          }
        }
      }
      &:nth-of-type(2) {
        padding-left: 5%;

        h1 {
          &:nth-of-type(1) {
            margin-bottom: 4rem;
          }
          &:nth-of-type(2) {
            text-align: center;
            margin-top: 5rem;
          }
        }
      }
    }
  }
}

.big-news-section {
  @extend %section;

  &-inner {
    @extend %inner-section;

    h1.banner-title {
      margin-bottom: 6rem;

      &:nth-of-type(2) {
        margin-top: 10rem;
      }
    }

    figure.news-pic-wrapper {
      width: 100%;
      height: auto;
      border: 1px solid $color-theme;
      box-shadow: 4px 4px 0px $color-theme;

      img {
        width: 100%;
        height: auto;

        &.news-pic--mobile {
          display: none;
        }

        @include media("<=phone") {
          display: none;
          &.news-pic--mobile {
            display: block !important;
          }
        }
      }
    }

    .news {
      h1 {
        margin: 5rem 0;
      }
      p {
        width: 100%;
        column-count: 2;

        @include media("<=phone") {
          width: 100%;
        }
      }
    }

    .news-list {
      margin-top: 10rem;
      width: 100%;
      height: auto;
      position: relative;
      @extend %flex-row-space;
      align-items: flex-start;

      @include media("<=phone") {
        flex-wrap: wrap;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
        transform: translateX(-50%);

        @include media("<=phone") {
          display: none;
        }
      }

      .news-column {
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
        height: auto;
        @include media("<=phone") {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 4rem 0;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        h1 {
          &.mini-title:not(.center) {
            margin-bottom: 5rem;
          }

          &.center {
            margin: 7.5rem 0;
            text-align: center;
          }
        }

        &:nth-of-type(1) {
          padding-right: 5%;
        }
        &:nth-of-type(2) {
          padding-left: 5%;
        }
      }
    }

    p {
      column-count: 2;
    }
  }
}

.home-latest__articles__wrapper {
  margin-top: 5vh;
}

.home-latest__btn__wrapper {
  margin-top: 10rem;
  text-align: center;
}

.article__item__link {
  padding: 8vh 2vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 2rem;
  transition: all 0.5s ease;
  border: 2px solid $color-theme;
  box-shadow: 4px 4px 0px $color-theme;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99%;
    height: 90%;
    border: 1px dashed $color-theme;

    @include media("<=phone") {
      width: 95%;
    }
  }

  &:hover {
    box-shadow: 0px 0px 0px $color-theme;
  }

  @include media("<=phone") {
    flex-wrap: wrap;
    padding: 4rem;
  }
}

.article__link__number {
  font-size: 1.2vw;
  flex: 0 0 10%;

  @include media("<=phone") {
    flex: 0 0 20%;
    max-width: 20%;
    font-size: 2rem;
  }
}

.article__link__title {
  font-size: 1.8vw;
  font-family: $font-c !important;
  font-weight: 600;
  flex: 0 0 20%;

  @include media("<=phone") {
    flex: 0 0 80%;
    max-width: 80%;
    font-size: 5vw;
  }
}

.article__link__paragrpah {
  font-size: 1.5vw;
  font-weight: 400;
  flex: 0 0 70%;
  line-height: 1.2;
  @include media("<=phone") {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 3rem;
    font-size: 2rem;
  }
}

.headline__link {
  font-family: $font-a;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

// -------------------- Announcement
.announcement__wrapper {
  border-bottom: 1px dashed $color-theme;
  border: 2px solid $color-theme;
  box-shadow: 4px 4px 0px $color-theme;
  width: 100%;
  margin-bottom: 10vh;
  padding: 4vh 5vh;
  position: relative;

  @include media("<=phone") {
    margin-bottom: 5vh;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99%;
    height: 90%;
    border: 1px dashed $color-theme;
  }
}

.announcement__title {
  font-family: $font-b !important;
  font-weight: 500;
  text-align: center;
  position: relative;
  margin-bottom: 2vh;
}

.announcement__content {
  text-align: center;
}

// nft
.nft-section {
  width: 100%;
  height: auto;
  position: relative;

  @include media("<=phone") {
    margin: 0 0 5rem 0;
  }

  &-inner {
    @extend %inner-section;
    height: auto;
    padding: 10rem 0 14rem 0;

    @include media("<=tablet") {
      .slick-list {
        overflow: visible !important;
      }
    }

    p.last {
      text-align: center;
      margin: 2rem 0 4rem 0;
    }

    .nft-slider {
      width: 100%;
      height: auto;
      position: relative;

      &-slide {
        width: 40rem !important;
        height: auto;
        border: 2px solid #000000;
        box-shadow: 5px 5px 0 #2e2929;
        padding: 2rem 2rem 4rem 2rem;
        position: relative;
        background: rgba($color: $color-black, $alpha: 0.1);

        @include media("<=tablet") {
          width: 90% !important;
        }

        @include media("<=phone") {
          width: 90% !important;
        }

        figure {
          width: 100%;
          height: 100%;
          overflow: hidden;
          border: 2px solid $color-black;

          img {
            width: 100%;
            height: auto;
          }
        }

        .slide-content {
          width: 100%;
          height: auto;
          padding: 2rem 0;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          p {
            font-size: 2rem;
          }

          .value {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            img {
              width: 1.5rem;
            }
          }
        }

        a {
          font-size: 2rem;
          text-align: center;
          width: 100%;
          height: auto;
          padding: 1rem 4rem;
          color: rgba($color: $color-white, $alpha: 1);
          background: rgba($color: $color-theme, $alpha: 1);
          margin-top: 2rem;
        }

        img.slide-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          opacity: 0.5 !important;
        }
      }

      .slick-dots {
        bottom: -5rem !important;

        li {
          button {
            transform-origin: center;
            transform: scale(2);
          }
        }
      }
    }
  }
}

.nft-slider-nothings {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 2rem;
  border: 2px solid $color-black;
  box-shadow: 5px 5px 0 rgba($color: $color-theme, $alpha: 1);

  &-inner {
    width: 100%;
    height: auto;
    border: 2px dashed $color-theme;
    padding: 5rem;

    h1 {
      font-family: $font-b !important;
    }
  }
}
