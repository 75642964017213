.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: z("navigation");
  padding: 2rem 0;
  border-bottom: 1px solid $color-black;
  overflow: hidden;
  transform-origin: center;
  transform: translateY(-100%);
  transition: all 0.7s ease;

  &.blurNavbar {
    backdrop-filter: blur(15px);
  }

  &.active {
    transform: translateY(0%);
  }

  &__inner {
    @extend %inner-section;
    @extend %flex-row-space;
    position: relative;

    .nav-box {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      width: 100%;
      height: fit-content;

      @include media("<=phone") {
        &:nth-child(1) {
          display: none;
        }
      }

      &:nth-child(2) {
        text-align: center;
        @include media("<=phone") {
          flex: 0 0 50%;
          max-width: 50%;
          text-align: left;
        }
      }

      &:nth-child(3) {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2.5rem;

        @include media("<=phone") {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }

    a.profile__icon {
      width: 6rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    img.logo-img {
      width: 14vw;
      height: auto;
      @media only screen and (max-width: 768px) {
        width: 18rem;
      }
      @media only screen and (max-width: 480px) {
        width: 30rem;
      }
    }

    button.wallet-btn {
      @extend %flex-row-center;
      color: $color-white;
      transition: all 0.5s ease;
      position: relative;
      overflow: hidden;
      border: 2px solid $color-black;
      box-shadow: 3px 3px 0 0 $color-theme;
      padding: 0.5rem 2rem;
      margin-top: 0 !important;

      &:hover {
        box-shadow: none;
      }

      @include media("<=phone") {
        padding: 1rem 1.5rem;
        width: 15rem;
      }

      span {
        &.wallet-text-pc {
          display: block;

          @include media("<=phone") {
            display: none;
          }
        }
        &.wallet-text-mobile {
          display: none;

          @include media("<=phone") {
            display: block;
            font-size: 2rem;
          }
        }
      }

      img {
        position: relative;
        transition: all 0.5s ease;
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;

        @include media("<=phone") {
          display: none;
        }
      }

      span {
        font-size: 1vw;
        font-weight: bold;
        color: $color-theme;
        font-family: $font-b;

        @include media("<=phone") {
          font-size: 1.4rem;
        }
      }
    }

    button.hamb-btn {
      transition: all 0.5s ease;
      width: 5rem;
      height: 5rem;
      border: 1px solid $color-black;
      box-shadow: 3px 3px 0 0 $color-theme;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      position: relative;

      &:hover {
        box-shadow: none;
      }

      &.active {
        span {
          &:nth-of-type(2) {
            transform: translateX(100%);
            opacity: 0;
          }
          &:nth-of-type(1) {
            transform: rotate(45deg) translateY(0rem);
          }
          &:nth-of-type(3) {
            transform: rotate(-45deg) translateY(0rem);
          }
        }
      }

      span {
        position: absolute;
        width: 80%;
        height: 2px;
        background: $color-theme;
        transform-origin: center;
        transition: all 0.3s ease;
        &:nth-of-type(1) {
          transform: translateY(-1rem);
        }
        &:nth-of-type(3) {
          transform: translateY(1rem);
        }
      }
    }
  }
}
