.footer {
  width: 100%;
  height: fit-content;

  &-inner {
    @extend %inner-section;
    padding: 10vh 0;
    position: relative;

    @include media("<=phone") {
      padding: 5vh 0;
    }

    .infinite-box {
      background: $color-theme;
      width: 100%;
      height: fit-content;
      padding: 3rem 2rem;
      text-align: center;
      overflow: hidden;

      h1 {
        position: relative;
        animation: move 30s linear infinite;
        color: $color-white;
        width: max-content;
        text-shadow: var(--title-width) 0 $color-white,
          calc(var(--title-width) * 2) 0 $color-white,
          calc(var(--title-width) * 3) 0 $color-white,
          calc(var(--title-width) * 4) 0 $color-white,
          calc(var(--title-width) * 5) 0 $color-white,
          calc(var(--title-width) * 6) 0 $color-white;

        @include media("<=phone") {
          width: 30vw;
          text-shadow: 30vw 0 $color-white, calc(30vw * 2) 0 $color-white,
            calc(30vw * 3) 0 $color-white, calc(30vw * 4) 0 $color-white,
            calc(30vw * 5) 0 $color-white, calc(30vw * 6) 0 $color-white;
        }
      }
    }

    .line {
      margin: 10vh 0 5vh 0;
    }

    .footer-content {
      width: 100%;
      @extend %flex-row-space;
      flex-wrap: wrap;

      p,
      a {
        flex: 0 0 33%;
        max-width: 33%;
        width: 100%;

        @include media("<=phone") {
          font-size: 3rem !important;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        text-align: center;

        @include media("<=phone") {
          margin-top: 2rem;
          &:nth-of-type(1) {
            justify-content: flex-start;
          }
          &:nth-of-type(2) {
            justify-content: flex-start !important;
          }
        }

        &:nth-of-type(2) {
          justify-content: flex-end;
        }
        img {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
}
