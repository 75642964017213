.hamb-menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z("modal");
  background: rgba($color: $color-theme, $alpha: 0.2);
  @extend %center;
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.hamb-menu {
  position: fixed;
  width: 50vw;
  height: 60vh;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -65%) scale(1.2);
  z-index: z("hamb-menu");
  background: $btn-bg;
  padding: 2.5rem;
  @extend %center;
  transition: all 0.6s ease;
  transform-origin: center;
  opacity: 0;
  visibility: hidden;

  @include media("<=phone") {
    width: 80%;
    height: auto;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.2);

    .hamb-menu-inner {
      padding: 10rem 4rem !important;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  img.hamb-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5 !important;
  }

  button.close-btn {
    position: absolute;
    top: 4vh;
    right: 2vw;
    font-size: 1vw;
    z-index: 10;
    font-family: $font-b;
    @extend %center;

    @include media("<=phone") {
      font-size: 2rem;
      right: 4vw;
    }
  }

  .hamb-menu-inner {
    width: 100%;
    height: 100%;
    border: 1px dashed $color-theme;
    @extend %flex-row-space;
    padding: 2rem 5rem;

    figure.hamb-pic-wrapper,
    .hamb-links {
      flex: 0 0 50%;
      max-width: 100%;
      height: 100%;
      overflow: hidden;

      @include media("<=phone") {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0 !important;
      }
    }

    figure {
      @include media("<=phone") {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    .hamb-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 2rem;
      padding-left: 4rem;
    }

    a.hamb-link {
      font-size: 1.5vw;
      font-weight: 500;
      transition: all 0.5s ease;
      padding: 1rem 2rem;
      border: 1px solid $color-theme;
      box-shadow: 2.5px 2.5px 0 $color-black;

      &:hover {
        box-shadow: none;
      }

      @include media("<=phone") {
        font-size: 4rem;
      }

      &:hover,
      &.active {
        color: $color-theme;
      }
    }

    .drop-box {
      width: 100%;
      height: auto;
      position: relative;

      button.drop-box-btn {
        font-size: 1.5vw;
        font-weight: 500;
        transition: all 0.5s ease;
        font-family: $font-b;
        padding: 0.6rem 2rem;
        border: 1px solid $color-theme;
        box-shadow: 2.5px 2.5px 0 $color-black;

        @include media("<=phone") {
          font-size: 4rem;
        }

        &.active {
          box-shadow: none;
        }

        &:hover {
          box-shadow: none;
        }
      }
    }

    .drop-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      z-index: 1;
      background: $btn-bg;
      padding: 4rem;
      transition: all 0.5s ease;
      opacity: 0;
      visibility: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @include media("<=phone") {
        width: 100%;
        align-items: center;
        padding: 4rem 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 90%;
        z-index: -1;
        border: 1px dashed $color-theme;
      }

      &.active {
        opacity: 1;
        visibility: visible;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
      }
    }

    .hamb-link-btn {
      border: 1px solid $color-theme;
      padding: 1rem 2rem;
      box-shadow: 2.5px 2.5px 0 $color-black;
      transition: all 0.5s ease;
      cursor: pointer;

      @include media("<=phone") {
        width: 80%;

        p {
          font-size: 2rem;
          width: 100%;
        }
      }

      &:hover {
        box-shadow: none;
      }

      &:not(:nth-of-type(1)) {
        margin-top: 2rem;
      }

      &.active {
        h1 {
          &::after {
            content: attr(data-text-active);
          }
        }
      }

      p {
        font-size: 1.5rem;
      }

      h1 {
        position: relative;
        width: max-content;
        font-size: 2.5rem;
        margin-bottom: 1rem;

        @include media("<=phone") {
          font-size: 3rem;
          margin-bottom: 2rem;
        }

        &::after {
          content: attr(data-text);
          position: absolute;
          top: 0;
          right: -6rem;
          width: auto;
          height: 100%;
          background: $color-theme;
          color: $color-white;
          border-radius: 20px;
          font-size: 1.2rem;
          font-family: $font-b;
          padding: 0 1rem;
          display: grid;
          place-items: center;
        }
      }
    }

    .social-links {
      width: 100%;
      @extend %flex-row-center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 3rem;
      padding: 2rem 0;
      border-top: 1px solid $color-theme;
      margin-top: 2rem;

      a {
        transition: all 0.3s ease;

        &:hover {
          transform: rotate(360deg) scale(1.2);
        }
        img {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }
}
