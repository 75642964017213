// Scroll hijeck
%scrolljeck {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}

html {
  background-color: $color-black;
  width: 100%;
  min-height: 100%;

  font-size: calc(100vw / 1920 * 10);
  line-height: 1;

  @include media("<tablet") {
    font-size: calc(100vw / 1024 * 10);
  }

  @include media("<phone") {
    font-size: calc(100vw / 750 * 10);
  }
}

body {
  //   @extend %scrolljeck;
  width: 100%;
  font-family: $font-c;
  color: $color-black;
  overflow-x: hidden;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: z("canvas");
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

.content,
#root {
  @extend %scrolljeck;
  //   overflow-y: scroll !important;
  //   width: 100%;
  //   height: auto;
  //   position: relative;
  //   overflow: hidden;
}

iframe {
  display: none !important;
}

img {
  pointer-events: none;
  transition: opacity 0.4s ease;
  vertical-align: middle;
  object-fit: cover;
  transition-delay: 1s;

  &.loaded {
    opacity: 1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-a !important;
}

.article__link,
.link {
  display: block;
  width: fit-content;
  margin-top: 1vh;
  font-weight: 600;
  position: relative;
  overflow: hidden;

  &:hover::after {
    transform: scaleX(1);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-theme;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease;
  }
}

.lazy-load-image-background {
  width: 100%;
  height: auto;
}

.lazy-load-desktop-wrapper {
  width: 100%;
  height: auto;

  @include media("<=tablet") {
    width: 0;
    height: 0;
  }
}

.lazy-load-mobile-wrapper {
  width: 0;
  height: 0;

  @include media("<=phone") {
    width: 100%;
    height: auto;
  }
}
