$color-gray: #343a40;
$down-feathers: #fffae7;
$super-silver: #eeeeee;
$color-paper-gray: #cdc6be;
$color-white: #fff;
$color-black: #000000;
$color-theme: #2e2929;
$color-danger: #dc3545;
$btn-bg: #8a8a8a;
$paper-color: #bebebe;

$font-a: "Abril Fatface", cursive;
$font-b: "Playfair Display", serif;
$font-c: "PT Serif", serif;

$ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);

$z-indexes: (
  "preloader",
  "navigation",
  "modal",
  "wallet-box",
  "hamb-menu",
  "content",
  "canvas"
);
