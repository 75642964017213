%page {
  height: 100%;
  left: 0;
  //   opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 0.7s ease;
  max-width: 100vw !important;
  width: 100vw !important;
  z-index: z("content");
}

%page--active {
  opacity: 1;
}

%content {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}

%section {
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  position: relative;
}

%inner-section {
  max-width: 90%;
  width: 100%;
  height: fit-content;
  margin: 0 auto;

  @include media("<=phone") {
    max-width: 95%;
  }
}

// bg
.paper-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: calc(var(--index) * 100vh);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: $paper-color;
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background: rgba($color: $color-black, $alpha: 0.5);
}
